import apiWrapper from "@/apiWrapper";
import store from "@/store";

class engineerDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetEngineersUpdate = -1;

    async getEngineers(){
        if(this.needsGetEngineersUpdate()){
            let response = await apiWrapper.getEngineers();
            store.commit("updateEngineerList", response.data);

            engineerDataAccess.lastGetEngineersUpdate = new Date().getTime();
        }
        
        return store.getters.engineerList;
    }

    needsGetEngineersUpdate(){
        return engineerDataAccess.lastGetEngineersUpdate === -1 ||
               new Date().getTime() - engineerDataAccess.lastGetEngineersUpdate >= engineerDataAccess.cacheTimeoutMilliseconds;
    }
}

export default engineerDataAccess;