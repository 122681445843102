<template>
  <div class="search-wrapper">
    <div class="title-bar search-title-bar">
      <h1 class="search-title">Engineers</h1>
      <div class="search-input-wrapper">
        <v-text-field
          class="search-input"
          :placeholder="'Search'"
          :no-data-text="'No engineers found'"
          :color="colors.PRIMARY"
          :loading="isLoadingOptions"
          v-model="searchText"
          v-on:input="updateEngineerList"
        ></v-text-field>
      </div>
    </div>
    <div class="user-list">
      <v-list class="transparent-element">
        <EngineerSearchResult
          v-for="engineer in displayedEngineers"
          :key="engineer.email"
          :engineer="engineer"
        />
      </v-list>
    </div>
  </div>
</template>

<script>
import colors from "@/colors"
import engineerDataAccess from "../../../dataAccess/engineerDataAccess";
import EngineerSearchResult from "./EngineerSearchResult";

export default {
  components: {
    EngineerSearchResult
  },
  data() {
    return {
      colors,
      isLoadingOptions: true,
      isLoadingResume: new Array(),
      engineerList: [],
      displayedEngineers: [],
      searchText: "",
      engineerDAO: new engineerDataAccess()
    };
  },
  methods: {
    goToEngineer(email) {
      this.$router.push("/skills?user=" + email);
    },
    clickContextMenu: function(event) {
      // Needed to stop the context menu click from
      // triggering the list element click as well
      event.stopPropagation();
    },
    updateEngineerList(){
      this.displayedEngineers = this.engineerList.filter(engineer => {
        return engineer.name.toUpperCase().includes(this.searchText.toUpperCase()) ||
               engineer.email.toUpperCase().includes(this.searchText.toUpperCase());
      })
    }
  },
  mounted: async function() {
    let engineers = await this.engineerDAO.getEngineers();
    engineers.forEach(element => {
      if (element.isEnabled) {
        this.engineerList.push(element);
        this.displayedEngineers.push(element);
      }
    });
    this.isLoadingOptions = false;
  }
};
</script>

<style scoped>
.search-wrapper {
  margin: 20px;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 800px;
  background-color: #fefefe;
}

.search-input-wrapper {
  margin-right: 25px;
  margin-left: 25px;
  width: 100%;
}

.search-title-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-title {
  margin-top: 12px;
}

.transparent-element {
  background-color: transparent !important;
}

.padded-tile {
  padding: 2px 15px;
}

.spacer {
  width: 15px;
}

@media screen and (max-width: 800px) {
  .search-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>