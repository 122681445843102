import apiWrapper from "@/apiWrapper";

class resumeDataAccess {
  async getResumeSharingLink(engineerName){
    let response = await apiWrapper.getResumeSharingLink(engineerName);
    return response.data;
  }

  async getResumeDownloadLink(engineerName){
    let response = await apiWrapper.getResumeDownloadLink(engineerName);
    return response.data;
  }
}

export default resumeDataAccess