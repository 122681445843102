<template>
  <div class="searchforengineers">
    <div class="title-bar">
      <h1>Search For Engineers</h1>
    </div>
    <div class="search-container">
      <EngineerSearch/>
    </div>
  </div>
</template>

<script>
import EngineerSearch from "./components/EngineerSearch.vue";

export default {
  name: "searchforengineers",
  components: {
    EngineerSearch
  }
};
</script>

<style scoped>
.search-container {
  text-align: left;
}
</style>