<template>
  <div>
    <v-progress-circular
      indeterminate
      :class="{'hidden': !isLoadingResume}"
      class="loading-resume-indicator"
      :color="colors.PRIMARY"
      :size="25"
      :width="3"
    ></v-progress-circular>
    <v-menu right>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click="clickContextMenu" :class="{ 'hidden': isLoadingResume }">
          <v-icon color="#cccccc">more_vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadResume(engineer.email, engineer.name)">
          <v-icon class="action-icon" :color="colors.PRIMARY">cloud_download</v-icon>
          <div class="spacer"></div>
          <v-list-item-title>Download Resume</v-list-item-title>
        </v-list-item>
        <v-list-item @click="emailResume(engineer.email, engineer.name)">
          <v-icon class="action-icon" :color="colors.PRIMARY">email</v-icon>
          <div class="spacer"></div>
          <v-list-item-title>Email Resume</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarTimeout"
      :color="'red'"
    >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import resumeDataAccess from "@/dataAccess/resumeDataAccess";

export default {
  name: "UserContextMenu",
  data() {
    return {
      colors,
      isLoadingResume: false,
      resumeDAO: new resumeDataAccess(),
      snackbarShown: false,
      snackbarMessage: "",
      snackbarTimeout: 3000
    };
  },
  props: ["engineer"],
  methods: {
    clickContextMenu: function(event) {
      // Needed to stop the context menu click from
      // triggering the element below it as well
      event.stopPropagation();
    },
    async downloadResume(engineerEmail, engineerName) {
      let downloadLink = "";
      try {
        this.isLoadingResume = true;

        downloadLink = await this.resumeDAO.getResumeDownloadLink(engineerName);

        this.isLoadingResume = false;
      } catch (ex) {
        this.isLoadingResume = false;

        if (ex.response.status === 404) {
          this.showResumeNotFoundError(engineerName);
        } else {
          this.showResumeRetrievalError();
        }
        return;
      }

      window.location.href = downloadLink;
    },
    async emailResume(engineerEmail, engineerName) {
      let resumeLink = "";
      try {
        this.isLoadingResume = true;

        resumeLink = await this.resumeDAO.getResumeSharingLink(engineerName);

        this.isLoadingResume = false;
      } catch (ex) {
        this.isLoadingResume = false;

        if (ex.response.status === 404) {
          this.showResumeNotFoundError(engineerName);
        } else {
          this.showResumeRetrievalError();
        }
        return;
      }

      location.href = `mailto:?&subject=${engineerName} Omnitech Resume&body=${this.getResumeEmailBody(
        engineerName,
        resumeLink
      )}`;
    },
    getResumeEmailBody(engineerName, resumeLink) {
      let resultString = `Visit the link below to view ${engineerName}'s resume\n\n\n`;
      resultString += `${resumeLink}\n\n\n`;
      resultString +=
        "NOTE: This access link will expire 14 days after receiving this email.\n\n\n";
      return encodeURIComponent(resultString);
    },
    showResumeNotFoundError(engineerName) {
      this.snackbarTimeout = 3000;
      this.snackbarMessage = `Could not find a resume for ${engineerName}`;
      this.snackbarShown = true;
    },
    showResumeRetrievalError() {
      this.snackbarTimeout = 3000;
      this.snackbarMessage = "Error retrieving resume";
      this.snackbarShown = true;
    }
  }
};
</script>

<style scoped>
.spacer {
  width: 15px;
}

.hidden {
  display: none;
}

.loading-resume-indicator{
  margin-right: 12px;
  margin-left: 12px;
}
</style>
