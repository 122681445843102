<template>
  <v-list-item class="padded-tile" :key="engineer.email" @click="goToEngineer(engineer.email)">
    <v-list-item-content>
      <v-list-item-title v-text="engineer.name"></v-list-item-title>
      <v-list-item-subtitle v-text="engineer.email" class="pl-4"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <UserContextMenu :engineer="engineer"/>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import UserContextMenu from "@/components/UserContextMenu";

export default {
  name: "EngineerSearchResult",
  components: {
    UserContextMenu
  },
  props: ["engineer"],
  methods: {
    goToEngineer(email) {
      this.$router.push("/skills?user=" + email);
    }
  }
};
</script>

